@use "../../../assets/scss/variables" as v;

.unauthorized-page {
  text-align: center;
  margin-top: 50px;
  color: v.$color1;
}

.unauthorized-page h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.unauthorized-page p {
  font-size: 1.2em;
}

.home-link {
  font-weight: bold;
}
