@use "./variables" as v;

@mixin reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin flexbox(
  $direction: row,
  $justify-content: flex-start,
  $align-items: flex-start,
  $gap: 0
) {
  display: flex;
  justify-content: $justify-content;
  flex-direction: $direction;
  align-items: $align-items;
  gap: $gap;
}

@mixin bg-image(
  $url,
  $size: cover,
  $position: center center,
  $repeat: no-repeat
) {
  background-image: url($url);
  background-repeat: $repeat;
  background-size: $size;
  background-position: $position;
}

@mixin watermark($bgcolor, $opacity: 0.8) {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bgcolor;
  opacity: $opacity;
}

@mixin custom-button() {
  color: v.$color6;
  background-color: v.$color1;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  transform-style: preserve-3d;
  border: none;

  &:hover {
    box-shadow: 0 1px 5px v.$color2;
    background-color: v.$color2;
    color: v.$color6;
  }
}

@mixin project-send-button() {
  margin: 1rem 1rem 0 1rem;
  background-color: v.$color6;
  color: v.$color2;
  border-color: v.$color4;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
  opacity: 1;

  &:hover {
    background-color: v.$color1;
    border-color: v.$color6;
  }

  &[disabled] {
    background-color: v.$color6;
    border-color: v.$color4;
    cursor: not-allowed;
    color: v.$color2;
    opacity: 0.5;
  }
}
