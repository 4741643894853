@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.groupCol {
  text-decoration: none;
}

.project-group {
  min-height: 61vh;

  .groupRow {
    margin: 0 0 3rem 0;

    > div:nth-child(n + 5) {
      //eliminates the extra margin caused by the last Col
      margin-left: 0;
    }
  }
}

.project-card {
  position: relative;
  border: 0.1rem solid white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  transform-style: preserve-3d;
  overflow: hidden;
  border-radius: 0.5rem;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px) translateZ(0);
    cursor: pointer;
    border: 0.1rem solid v.$color6;
  }

  .image {
    overflow: hidden;
    height: 200px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .details {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h5 {
      padding-top: 0.4rem;
      margin-bottom: 1rem;
      font-size: 1rem;
      color: v.$color1;
      height: 3rem;
      overflow: hidden;
      text-overflow: clip;
      white-space: normal;
      position: relative;
      text-overflow: ellipsis;
    }

    .desc {
      padding-top: 0.5rem;
      margin-bottom: 1rem;
      font-size: 1rem;
      line-height: 1.5rem;
      color: v.$color1;
      height: 8rem;
      overflow: hidden;
      text-overflow: clip;
      white-space: normal;
      position: relative;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      padding-right: 1rem;
    }

    .desc::after {
      content: "...";
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
      color: v.$color5;
    }

    .progress {
      width: 100%;
    }

    .progress-info {
      @include m.flexbox();
      width: 100%;
      justify-content: space-between;

      .location {
        align-self: center;

        svg {
          font-size: 1rem;
        }

        svg,
        h5 {
          width: 100%;
          color: v.$color1;
        }

        h5 {
          font-size: 0.8rem;
        }
      }

      .under-progress {
        padding-top: 0.5rem;
        font-size: 1rem;
        width: 100%;
        text-align: right;
        color: v.$color2;

        span {
          font-size: 0.7rem;
        }
      }
    }
  }
}

.button-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0;
    padding: 10px 20px;
    background-color: v.$color6;
    opacity: 0.7;
    color: v.$color2;
    border: none;
    box-shadow: none;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
    width: 100%;
    min-height: 3rem;
  }
}

.project-card:hover .button-wrapper {
  height: 6%;
  opacity: 1;
}

.more-button-div {
  text-align: right;

  .more-button {
    margin: 1rem 2rem 0rem 0rem;

    @include m.custom-button();
  }
}
