@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

body {
  margin: 0;
  padding: 0;
}

.board {
  .contact {
    margin-top: 1rem;
    @include m.flexbox();

    flex-direction: column;
    align-items: center;

    a {
      text-decoration: none;
    }
  }

  .logo-auth {
    width: 80%;

    padding: 0 0 0rem 4rem;
  }

  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  h3 {
    padding-left: 0.2rem;
    padding-top: 1rem;
    text-align: center;
  }

  input {
    padding: 1rem;
    margin-bottom: 1rem;
    background: v.$color4;
  }

  input:nth-of-type(1) {
    margin-top: 1rem;
  }

  input:focus {
    padding: 1rem;
    background: v.$color6;
  }

  button {
    width: 100%;
    padding: 1rem;
    border: none;
    font-weight: 600;

    &:hover {
      background-color: v.$color2;
    }
  }

  .command-line {
    text-align: center;
    padding-right: 0.5rem;
    font-size: 0.9rem;

    .command {
      color: v.$color3;
      cursor: pointer;
    }
  }
}

.board::after {
  content: "";
  position: absolute;
  background-color: v.$color4;
  width: 100vw;
  min-height: 100vh;
  z-index: -3;

  @media (max-width: 1100px) {
    height: 170vh;
  }
}
