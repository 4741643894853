@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* LIBRARIES */
@import "bootstrap/scss/bootstrap";
@import "sweetalert2/src/variables";
@import "sweetalert2/src/sweetalert2";



//general settings
