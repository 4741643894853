@use "../../../../assets/scss/variables"as v;

.section-title {
    h2 {
        font-weight: bold;
        font-size: 2rem;
        text-align: left;
        color: v.$color1;
        margin-bottom: 2rem;
        border-bottom: 1px solid v.$color1;
        padding-bottom: 1rem;
    }
}